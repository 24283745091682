exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-author-index-en-jsx": () => import("./../../../src/pages/author/index.en.jsx" /* webpackChunkName: "component---src-pages-author-index-en-jsx" */),
  "component---src-pages-author-index-ru-jsx": () => import("./../../../src/pages/author/index.ru.jsx" /* webpackChunkName: "component---src-pages-author-index-ru-jsx" */),
  "component---src-pages-author-index-uk-jsx": () => import("./../../../src/pages/author/index.uk.jsx" /* webpackChunkName: "component---src-pages-author-index-uk-jsx" */),
  "component---src-pages-back-end-roadmap-index-en-jsx": () => import("./../../../src/pages/back-end-roadmap/index.en.jsx" /* webpackChunkName: "component---src-pages-back-end-roadmap-index-en-jsx" */),
  "component---src-pages-back-end-roadmap-index-ru-jsx": () => import("./../../../src/pages/back-end-roadmap/index.ru.jsx" /* webpackChunkName: "component---src-pages-back-end-roadmap-index-ru-jsx" */),
  "component---src-pages-back-end-roadmap-index-uk-jsx": () => import("./../../../src/pages/back-end-roadmap/index.uk.jsx" /* webpackChunkName: "component---src-pages-back-end-roadmap-index-uk-jsx" */),
  "component---src-pages-css-questions-index-en-jsx": () => import("./../../../src/pages/css-questions/index.en.jsx" /* webpackChunkName: "component---src-pages-css-questions-index-en-jsx" */),
  "component---src-pages-css-questions-index-ru-jsx": () => import("./../../../src/pages/css-questions/index.ru.jsx" /* webpackChunkName: "component---src-pages-css-questions-index-ru-jsx" */),
  "component---src-pages-css-questions-index-uk-jsx": () => import("./../../../src/pages/css-questions/index.uk.jsx" /* webpackChunkName: "component---src-pages-css-questions-index-uk-jsx" */),
  "component---src-pages-front-end-roadmap-index-en-jsx": () => import("./../../../src/pages/front-end-roadmap/index.en.jsx" /* webpackChunkName: "component---src-pages-front-end-roadmap-index-en-jsx" */),
  "component---src-pages-front-end-roadmap-index-ru-jsx": () => import("./../../../src/pages/front-end-roadmap/index.ru.jsx" /* webpackChunkName: "component---src-pages-front-end-roadmap-index-ru-jsx" */),
  "component---src-pages-front-end-roadmap-index-uk-jsx": () => import("./../../../src/pages/front-end-roadmap/index.uk.jsx" /* webpackChunkName: "component---src-pages-front-end-roadmap-index-uk-jsx" */),
  "component---src-pages-html-questions-index-en-jsx": () => import("./../../../src/pages/html-questions/index.en.jsx" /* webpackChunkName: "component---src-pages-html-questions-index-en-jsx" */),
  "component---src-pages-html-questions-index-ru-jsx": () => import("./../../../src/pages/html-questions/index.ru.jsx" /* webpackChunkName: "component---src-pages-html-questions-index-ru-jsx" */),
  "component---src-pages-html-questions-index-uk-jsx": () => import("./../../../src/pages/html-questions/index.uk.jsx" /* webpackChunkName: "component---src-pages-html-questions-index-uk-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../../../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-ru-jsx": () => import("./../../../src/pages/index.ru.jsx" /* webpackChunkName: "component---src-pages-index-ru-jsx" */),
  "component---src-pages-index-uk-jsx": () => import("./../../../src/pages/index.uk.jsx" /* webpackChunkName: "component---src-pages-index-uk-jsx" */),
  "component---src-pages-javascript-questions-index-en-jsx": () => import("./../../../src/pages/javascript-questions/index.en.jsx" /* webpackChunkName: "component---src-pages-javascript-questions-index-en-jsx" */),
  "component---src-pages-javascript-questions-index-ru-jsx": () => import("./../../../src/pages/javascript-questions/index.ru.jsx" /* webpackChunkName: "component---src-pages-javascript-questions-index-ru-jsx" */),
  "component---src-pages-javascript-questions-index-uk-jsx": () => import("./../../../src/pages/javascript-questions/index.uk.jsx" /* webpackChunkName: "component---src-pages-javascript-questions-index-uk-jsx" */),
  "component---src-pages-programming-questions-index-en-jsx": () => import("./../../../src/pages/programming-questions/index.en.jsx" /* webpackChunkName: "component---src-pages-programming-questions-index-en-jsx" */),
  "component---src-pages-programming-questions-index-ru-jsx": () => import("./../../../src/pages/programming-questions/index.ru.jsx" /* webpackChunkName: "component---src-pages-programming-questions-index-ru-jsx" */),
  "component---src-pages-programming-questions-index-uk-jsx": () => import("./../../../src/pages/programming-questions/index.uk.jsx" /* webpackChunkName: "component---src-pages-programming-questions-index-uk-jsx" */),
  "component---src-pages-react-questions-index-en-jsx": () => import("./../../../src/pages/react-questions/index.en.jsx" /* webpackChunkName: "component---src-pages-react-questions-index-en-jsx" */),
  "component---src-pages-react-questions-index-ru-jsx": () => import("./../../../src/pages/react-questions/index.ru.jsx" /* webpackChunkName: "component---src-pages-react-questions-index-ru-jsx" */),
  "component---src-pages-react-questions-index-uk-jsx": () => import("./../../../src/pages/react-questions/index.uk.jsx" /* webpackChunkName: "component---src-pages-react-questions-index-uk-jsx" */),
  "component---src-pages-typescript-questions-index-en-jsx": () => import("./../../../src/pages/typescript-questions/index.en.jsx" /* webpackChunkName: "component---src-pages-typescript-questions-index-en-jsx" */),
  "component---src-pages-typescript-questions-index-ru-jsx": () => import("./../../../src/pages/typescript-questions/index.ru.jsx" /* webpackChunkName: "component---src-pages-typescript-questions-index-ru-jsx" */),
  "component---src-pages-typescript-questions-index-uk-jsx": () => import("./../../../src/pages/typescript-questions/index.uk.jsx" /* webpackChunkName: "component---src-pages-typescript-questions-index-uk-jsx" */),
  "component---src-pages-web-technologies-questions-index-en-jsx": () => import("./../../../src/pages/web-technologies-questions/index.en.jsx" /* webpackChunkName: "component---src-pages-web-technologies-questions-index-en-jsx" */),
  "component---src-pages-web-technologies-questions-index-ru-jsx": () => import("./../../../src/pages/web-technologies-questions/index.ru.jsx" /* webpackChunkName: "component---src-pages-web-technologies-questions-index-ru-jsx" */),
  "component---src-pages-web-technologies-questions-index-uk-jsx": () => import("./../../../src/pages/web-technologies-questions/index.uk.jsx" /* webpackChunkName: "component---src-pages-web-technologies-questions-index-uk-jsx" */)
}

